import { Component, Input } from '@angular/core';
import { ImpactListModel } from '../../dashboard/assessment-list/impact-list.model';
import {
  AssessmentSource,
  DashboardService,
  GoToQuestionnaireAction,
  ProfileService,
  StartScoringAction,
} from '../../dashboard';
import { UserStatus } from '../../dashboard/assessment-list/user-status.enum';
import { AssessmentListModel } from '../../dashboard/assessment-list/assessment-list.model';
import { GoToImpactScanRevampAction } from '../../dashboard/xs/actions/go-to-impact-scan-revamp.action';
import { QuestionnaireType } from '../../dashboard/scoreboard/models/questionnaire.type';
import { ViewQuestionnaireAction } from '../../dashboard/xs/actions/view-questionnaire.action';
import { AssessmentVersion } from '../../dashboard/scoreboard/models/scoring.model';

@Component({
  selector: 'app-assessment-group-revamp',
  templateUrl: './assessment-group.component.html',
  styleUrls: ['./assessment-group.component.scss'],
})
export class AssessmentRevampGroupComponent {
  @Input()
  impact!: ImpactListModel;

  @Input()
  expanded = false;

  constructor(public readonly dashboardService: DashboardService, public profileService: ProfileService) {}

  startAssessment(): void {
    this.dashboardService.dispatchAction(
      new StartScoringAction({ impactScanId: parseInt(this.impact.id, 10), source: AssessmentSource.ASSESSMENT_LIST }),
    );
  }

  goToImpact(impact: ImpactListModel): void {
    this.dashboardService.dispatchAction(
      new GoToImpactScanRevampAction({
        id: parseInt(impact.id, 10),
        status: impact.status,
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goToAssessment(assessment: AssessmentListModel): void {
    this.dashboardService.dispatchAction(
      new GoToQuestionnaireAction({
        id: assessment.id,
        status: assessment.status,
        type: QuestionnaireType.ASSESSMENT,
        version: assessment.assessmentVersion ?? AssessmentVersion.REVAMP,
        disabled: assessment.disabled,
        unfinished: assessment.unfinished,
        viewOnly: assessment.viewOnly,
      }),
    );
  }

  isCurrentUser(userEmail: string): boolean {
    return userEmail === this.profileService.getProfile().email;
  }

  isInactive(userStatus: UserStatus): boolean {
    return userStatus === UserStatus.INACTIVE;
  }

  viewImpact(impact: ImpactListModel): void {
    this.goToImpact(impact);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  viewAssessment(assessment: AssessmentListModel): void {
    this.dashboardService.dispatchAction(
      new ViewQuestionnaireAction({
        id: assessment.id,
        status: assessment.status,
        type: QuestionnaireType.ASSESSMENT,
        version: assessment.assessmentVersion,
        userStatus: assessment.userStatus,
        firstName: assessment.firstName,
        lastName: assessment.lastName,
        disabled: assessment.disabled,
        viewOnly: assessment.viewOnly,
        unfinished: assessment.unfinished,
      }),
    );
  }

  isInProgress(scoring: AssessmentListModel): boolean {
    return scoring.unfinished && !scoring.disabled;
  }

  isCompleted(scoring: AssessmentListModel): boolean {
    return !scoring.unfinished && !scoring.disabled;
  }

  isDisabled(scoring: AssessmentListModel): boolean {
    return scoring.disabled;
  }

  canContinue(scoring: AssessmentListModel): boolean {
    return scoring.unfinished && !scoring.viewOnly;
  }

  canContinueImpact(scoring: AssessmentListModel): boolean {
    return scoring.unfinished && !scoring.viewOnly && scoring.version === 'ESG_MANAGED';
  }

  hasResults(scoring: AssessmentListModel): boolean {
    return !scoring.unfinished;
  }

  canView(scoring: AssessmentListModel): boolean {
    return scoring.viewOnly;
  }

  isEligible(): boolean {
    return !(
      this.impact.assessmentList.find((assessment) => assessment.assessmentVersion !== AssessmentVersion.MANAGED) !==
      undefined
    );
  }
}
