import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialDesignModule, SharedModule } from '../../../shared';
import { TranslateModule } from '@ngx-translate/core';
import { QuestionsModule } from '../../../shared/questions/questions.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ImpactScanRevampComponent } from './impact-scan-revamp.component';
import { CompanyContextOneComponent } from './company-context-one/company-context-one.component';
import { StakeholdersComponent } from './stakeholders/stakeholders.component';
import { CompanyContextTwoComponent } from './company-context-two/company-context-two.component';
import { ImpactScanRevampRouting } from './impact-scan-revamp.routing';
import { GovernanceComponent } from './governance/governance.component';
import { ThemesComponent } from './themes/themes.component';
import { AddThemePopupComponent } from './themes/add-theme/add-theme-popup.component';
// eslint-disable-next-line max-len
import { PrefillSelectorDropdownComponent } from '../impact-scan/impact-prefill-popup/prefill-selector-dropdown/prefill-selector-dropdown.component';
import { ChangedThemePopupComponent } from './themes/changed-theme-popup/changed-theme-popup.component';

@NgModule({
  declarations: [
    ImpactScanRevampComponent,
    CompanyContextOneComponent,
    CompanyContextTwoComponent,
    GovernanceComponent,
    StakeholdersComponent,
    ThemesComponent,
    AddThemePopupComponent,
    PrefillSelectorDropdownComponent,
    ChangedThemePopupComponent,
  ],
  imports: [
    MaterialDesignModule,
    TranslateModule,
    CommonModule,
    SharedModule,
    QuestionsModule,
    ReactiveFormsModule,
    ImpactScanRevampRouting,
  ],
  exports: [PrefillSelectorDropdownComponent],
})
export class ImpactScanRevampModule {}
