/* eslint-disable no-underscore-dangle */
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import sectorGbJson from '../../../assets/json/sectors-gb.json';
import sectorNlJson from '../../../assets/json/sectors-nl.json';
import { SectorModel } from './section-models/sectorModel';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { DashboardService } from '../../dashboard';
import { isCountryCodeDutchLike } from '../countries/country-service';

@Component({
  selector: 'app-dropdown-sectors',
  templateUrl: './dropdown-sectors.component.html',
  styleUrls: ['./dropdown-sectors.component.scss'],
})
export class DropdownSectorsComponent implements OnInit {
  searchSectorsCtrl = new FormControl();

  // @ts-ignore
  sectors: SectorModel[];

  filteredSectors!: SectorModel[];

  @Input()
  currentValue = '';

  @Input()
  required = false;

  private _country = '';

  get country(): string {
    return this._country;
  }

  @Input()
  set country(newValue: string) {
    const oldValue = this._country;

    if (newValue) {
      this._country = newValue.toLowerCase();
    }
    this.updateCurrentSectorJson();
    // if this is the "init" then we don't want to clear the existing value
    if (!oldValue || oldValue.length === 0) {
      return;
    }
    // if it is past the init and the country was changed in a way
    // that would actually change the list, then clear existing value
    if (
      (oldValue === 'nl' && newValue.toLowerCase() !== 'nl') ||
      (oldValue !== 'nl' && newValue.toLowerCase() === 'nl')
    ) {
      // the reason 'nl' is hardcoded here is because that's the only country
      // that doesn't use the english list (for the subsidiaries, the country
      // can be anything, but the list will stay english if we're not switching
      // to or from nl, so we shouldn't empty the value)
      this.currentValue = '';
      this.onOptionSelected();
    }
  }

  @Input()
  disabled!: boolean;

  @Input()
  holdingError?: boolean;

  @Output()
  outputValue = new EventEmitter<string>();

  @Output()
  blurEmitter = new EventEmitter<void>();

  constructor(public service: DashboardService) {}

  ngOnInit(): void {
    this.updateCurrentSectorJson();
    this.searchSectorsCtrl.valueChanges
      .pipe(
        filter((res) => res !== null && res !== undefined && res.length >= 1),
        distinctUntilChanged(),
        debounceTime(500),
      )
      .subscribe((value) => {
        this.filter(value);
      });
  }

  updateCurrentSectorJson(): void {
    if (isCountryCodeDutchLike(this.country)) {
      this.sectors = sectorNlJson as SectorModel[];
    } else {
      this.sectors = sectorGbJson as SectorModel[];
    }
    this.filteredSectors = this.sectors;
  }

  onOptionSelected(): void {
    this.outputValue.emit(this.currentValue);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter(event: string): void {
    this.filteredSectors = this.sectors.filter(
      (sector) =>
        sector.description.toLowerCase().includes(event.toLowerCase()) ||
        sector.localSectorCode.toLowerCase().includes(event.toLowerCase()),
    );
    if (this.filteredSectors.length === 0) {
      this.filteredSectors = this.sectors;
    }
  }

  onBlur(): void {
    this.blurEmitter.emit();
  }
}
