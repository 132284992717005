import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRouting } from './auth.routing';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialDesignModule, SharedModule } from '../shared';
import { SmeGuard } from './sme.guard';
import { LoggedInGuard } from './logged-in.guard';
import { FrontpageComponent, GridtilesComponent, LoginComponent } from './login';
import {
  ConfirmEmailComponent,
  CreateAccountComponent,
  PasswordComponent,
  ResetPasswordCheckComponent,
  ResetPasswordComponent,
} from './signup';
import { AccountantInviteeSignupComponent, AccountantSignupComponent } from './accountant';
import { LeftWebsiteComponent } from './left-website/left-website.component';
import { OnboardingGuard } from './onboarding.guard';
import { PrivacyPopUpComponent } from './signup/create-account/privacy-pop-up/privacy-pop-up.component';
import { FirstLoginPopupComponent } from './login/first-login-popup/first-login-popup.component';
import { WelcomePopupComponent } from '../onboarding/welcome-popup/welcome-popup.component';
import { PaymentComponent } from './signup/create-account/payment/payment.component';
import { ConfirmFailedComponent } from './signup/confirm-failed/confirm-failed.component';
import { FreeComponent } from './signup/create-account/free/free.component';
import { SignUpComponent } from './signup/create-account/sign-up/sign-up.component';
import { BillingComponent } from './signup/create-account/billing/billing.component';
// eslint-disable-next-line max-len
import { TermsAndConditionsComponent } from './signup/create-account/terms-and-conditions/terms-and-conditions.component';
import { InvitedComponent } from './signup/create-account/invited/invited.component';

@NgModule({
  declarations: [
    LoginComponent,
    FrontpageComponent,
    GridtilesComponent,
    CreateAccountComponent,
    PasswordComponent,
    ResetPasswordComponent,
    ResetPasswordCheckComponent,
    ConfirmEmailComponent,
    AccountantSignupComponent,
    AccountantInviteeSignupComponent,
    LeftWebsiteComponent,
    PrivacyPopUpComponent,
    FirstLoginPopupComponent,
    WelcomePopupComponent,
    PaymentComponent,
    ConfirmFailedComponent,
    FreeComponent,
    SignUpComponent,
    BillingComponent,
    TermsAndConditionsComponent,
    InvitedComponent,
  ],
  imports: [CommonModule, AuthRouting, MaterialDesignModule, FormsModule, TranslateModule, SharedModule],
  providers: [SmeGuard, LoggedInGuard, OnboardingGuard],
})
export class AuthModule {}
