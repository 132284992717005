import { Co2CalculationListItemModel } from '../model/co2-calculation-list-item.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { Co2CalculatorService } from '../co2-calculator.service';
import { LogoutSuccessAction } from '../../auth/xs/actions/logout-success.action';
import { EnterpriseStateModel } from '../../enterprise/xs/enterprise.state';
import { GetCo2CalculationsListAction } from './actions/get-co2-calculations-list.action';
import { StartCo2CalculationAction } from './actions/start-co2-calculation.action';
import { filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SetCo2QuestionnaireIdAction } from './actions/set-co2-questionnaire-id.action';
import { FinishCo2CalculationAction } from './actions/finish-co2-calculation.action';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// eslint-disable-next-line max-len
import { C02StartCalculationPopUpComponent } from '../popups/c02-start-calculation-pop-up/c02-start-calculation-pop-up.component';
import { GetCo2CalculationsListOnEmptyAction } from './actions/get-co2-calculations-list-on-empty.action';
import { Co2CalculationResultModel } from '../model/co2-calculation-result.model';
import { SetCo2ResultsIdAction } from './actions/set-co2-results-id.action';
import { GetCo2ResultsAction } from './actions/get-co2-results.action';
import { CheckCo2Action } from './actions/check-co2.action';
// eslint-disable-next-line max-len
import { Co2CalculationStartPopupComponent } from '../popups/co2-calculation-start-popup/co2-calculation-start-popup.component';
import { Co2ReportingPeriodModel } from '../popups/c02-start-calculation-pop-up/co2-reporting-period.model';

export interface Co2CalculatorStateModel {
  co2Calculations?: Co2CalculationListItemModel[];
  co2QuestionnaireId?: string;
  co2ResultsId?: string;
  co2Results: Co2CalculationResultModel;
  firstCo2Check?: boolean;
}

@State<Co2CalculatorStateModel>({ name: 'co2Calculator' })
@Injectable()
export class Co2CalculatorState {
  @Selector()
  static co2Calculations(state: Co2CalculatorStateModel): Co2CalculationListItemModel[] {
    return state.co2Calculations ? [...state.co2Calculations] : [];
  }

  @Selector()
  static co2QuestionnaireId(state: Co2CalculatorStateModel): string | undefined {
    return state.co2QuestionnaireId;
  }

  @Selector()
  static co2Results(state: Co2CalculatorStateModel): Co2CalculationResultModel | undefined {
    return state.co2Results;
  }

  @Selector()
  static isFirstCalculationCheck(state: Co2CalculatorStateModel): boolean {
    return !!state.firstCo2Check;
  }

  constructor(
    private readonly co2CalculatorService: Co2CalculatorService,
    private readonly ngZone: NgZone,
    private readonly dialog: MatDialog,
  ) {}

  @Action(GetCo2CalculationsListAction)
  getCo2Calculations(ctx: StateContext<Co2CalculatorStateModel>): Observable<Co2CalculationListItemModel[]> {
    return this.co2CalculatorService.getCo2CalculationsFromBackend().pipe(
      tap<Co2CalculationListItemModel[]>((list) => {
        ctx.patchState({ co2Calculations: list });
      }),
    );
  }

  @Action(CheckCo2Action)
  co2PopupShown(ctx: StateContext<Co2CalculatorStateModel>, action: CheckCo2Action): void {
    ctx.patchState({ firstCo2Check: !action.payload.hasBeenChecked });
  }

  @Action(GetCo2CalculationsListOnEmptyAction)
  getCo2CalculationsOnEmpty(ctx: StateContext<Co2CalculatorStateModel>): Observable<Co2CalculationListItemModel[]> {
    const calculations = ctx.getState().co2Calculations;

    if (calculations !== undefined && calculations.length > 0) {
      return new Observable<Co2CalculationListItemModel[]>((observer) => {
        observer.next(ctx.getState().co2Calculations);
        observer.complete();
      });
    }

    return this.co2CalculatorService.getCo2CalculationsFromBackend().pipe(
      tap<Co2CalculationListItemModel[]>((list) => {
        ctx.patchState({ co2Calculations: list });
      }),
    );
  }

  @Action(StartCo2CalculationAction)
  showCalculationStartPopUp(ctx: StateContext<Co2CalculatorStateModel>): void {
    const dialog = this.dialog.open(Co2CalculationStartPopupComponent, { width: '75vw', autoFocus: false });

    dialog.afterClosed().subscribe((start: boolean) => {
      if (start) {
        this.showRestatementPopup(ctx);
      }
    });
  }

  private showRestatementPopup(ctx: StateContext<Co2CalculatorStateModel>): void {
    this.startCalculationPopUp()
      .afterClosed()
      .subscribe((dialogResponse: Co2ReportingPeriodModel) => {
        if (dialogResponse.continue) {
          this.startCalculation(dialogResponse, ctx);
        }
      });
  }

  private startCalculationPopUp(): MatDialogRef<C02StartCalculationPopUpComponent> {
    return this.dialog.open(C02StartCalculationPopUpComponent, {
      width: '60vw',
      autoFocus: false,
      disableClose: true,
    });
  }

  private startCalculation(dialogResponse: Co2ReportingPeriodModel, ctx: StateContext<Co2CalculatorStateModel>): void {
    this.co2CalculatorService.startLoading();

    this.co2CalculatorService.startCalculation(dialogResponse.reportingPeriodStart).subscribe((response) => {
      ctx.patchState({ co2QuestionnaireId: response.calculationId });
      this.co2CalculatorService.stopLoading();
      this.ngZone.run(() => {
        this.co2CalculatorService.navigateToPage('/dashboard/sme/co2calculator/questionnaire');
      });
    });
  }

  @Action(SetCo2QuestionnaireIdAction)
  setCo2QuestionnaireId(ctx: StateContext<Co2CalculatorStateModel>, action: SetCo2QuestionnaireIdAction): void {
    ctx.patchState({ co2QuestionnaireId: action.payload.id });
  }

  @Action(FinishCo2CalculationAction)
  finishCo2Calculation(
    ctx: StateContext<Co2CalculatorStateModel>,
    action: FinishCo2CalculationAction,
  ): Observable<unknown> {
    return this.co2CalculatorService.finishCalculation(action.payload.id).pipe(
      tap(() => {
        ctx.patchState({ co2ResultsId: action.payload.id });
      }),
    );
  }

  @Action(SetCo2ResultsIdAction)
  setCo2ResultsId(ctx: StateContext<Co2CalculatorStateModel>, action: SetCo2ResultsIdAction): void {
    ctx.patchState({ co2ResultsId: action.payload.id });
  }

  @Action(GetCo2ResultsAction)
  getCo2Results(ctx: StateContext<Co2CalculatorStateModel>): Observable<Co2CalculationResultModel> {
    const toLoadFromBackend = ctx.getState().co2ResultsId;

    if (!toLoadFromBackend) {
      return this.co2CalculatorService.getLatestResultsFromBackend().pipe(
        filter((results) => results !== null),
        tap<Co2CalculationResultModel>((results) => {
          ctx.patchState({ co2ResultsId: results.calculationId, co2Results: results });
        }),
      );
    }

    const lastLoadedFromBackend = ctx.getState().co2Results;

    if (!lastLoadedFromBackend || lastLoadedFromBackend.calculationId !== toLoadFromBackend) {
      return this.co2CalculatorService.getResultsFromBackend(toLoadFromBackend).pipe(
        tap<Co2CalculationResultModel>((results) => {
          ctx.patchState({ co2Results: results });
        }),
      );
    }

    return new Observable<Co2CalculationResultModel>((observer) => {
      observer.next(ctx.getState().co2Results);
      observer.complete();
    });
  }

  @Action(LogoutSuccessAction)
  clearState(ctx: StateContext<EnterpriseStateModel>): void {
    ctx.setState({});
  }
}
