import { Component, OnInit, ViewChild } from '@angular/core';
import { CategoryListItemModel } from '../../../shared/questionnaire-header/category-list-item.model';
import { ImpactScanRevampDetailsModel, ImpactScanRevampModel } from './impact-scan-revamp.model';
import { CompanyService } from '../../profile';
import { StatusInfoModel } from '../../../shared/questionnaire-header/status-info.model';
import { UserStatus } from '../../assessment-list/user-status.enum';
import { ImpactScanRevampService } from './impact-scan-revamp.service';
import { UpdateImpactScanAnswerAction } from './xs/update-impact-scan-answer.action';
import { ImpactScanRevampValidatorService } from './impact-scan-revamp-validator.service';
import { CalculateThemesAction } from './xs/calculate-themes.action';
import { UnansweredQuestionModel } from '../../../shared/unanswered-popup-simple/unanswered-question.model';
// eslint-disable-next-line max-len
import { UnansweredPopupSimpleComponent } from '../../../shared/unanswered-popup-simple/unanswered-popup-simple.component';
import { MatDialog } from '@angular/material/dialog';
// eslint-disable-next-line max-len
import { EeveryTranslateService } from '../../../shared/eevery.translate.service';
import { SideNavModel } from '../../../shared/sidenav';
import { ImpactScanPageDirective } from './abstract-impact-scan-page';
// eslint-disable-next-line max-len
import { CompleteScoringCheckPopUpComponent } from '../complete-scoring-check-pop-up/complete-scoring-check-pop-up.component';
import { DashboardService } from '../../dashboard.service';

@Component({
  selector: 'app-impact-scan-revamp',
  templateUrl: './impact-scan-revamp.component.html',
  styleUrls: ['./impact-scan-revamp.component.scss'],
})
export class ImpactScanRevampComponent implements OnInit {
  impactScanDetails?: ImpactScanRevampDetailsModel;

  navMenu: SideNavModel[] = [];

  currentIndex = 0;

  sections = ['company_context_one', 'company_context_two', 'governance', 'stakeholders'];

  impactScan: ImpactScanRevampModel = {
    controlType: '',
    customers: [
      { name: '', countryCode: '' },
      { name: '', countryCode: '' },
      { name: '', countryCode: '' },
    ],
    executiveDirectors: undefined,
    hasMultipleShareholders: undefined,
    hasNonExecutiveDirectors: undefined,
    hasOtherStakeholders: undefined,
    legalEntityType: '',
    otherStakeholders: [],
    products: '',
    sectorCode: '',
    suppliers: [],
    subsidiaries: [],
    stakeholders: [],
  };

  country = 'GB';

  language!: string;

  categoriesForHeader!: CategoryListItemModel[];

  statusInfo?: StatusInfoModel;

  @ViewChild('page')
  currentPage!: ImpactScanPageDirective;

  highLightUnansweredQuestion = false;

  showCheckmarks = false;

  loading = false;

  navigationTarget = { route: 'dashboard/sme', params: {} };

  constructor(
    private translateService: EeveryTranslateService,
    private companyService: CompanyService,
    private impactScanService: ImpactScanRevampService,
    private dialog: MatDialog,
    public readonly validatorService: ImpactScanRevampValidatorService,
    private dashboardService: DashboardService,
  ) {}

  ngOnInit(): void {
    this.country = `${this.companyService.getCompany().country}`;
    this.language = this.translateService.getLanguage();

    this.getLatestImpactScanDetails();
    this.initPage();
    this.categoriesForHeader = this.getCategoriesForHeader();
  }

  getLatestImpactScanDetails(): void {
    this.loading = true;
    const id = this.impactScanService.getImpactScanIdFromState();

    if (id === undefined || id === null) {
      this.impactScanService.navigateToPage('/dashboard/sme');
    } else {
      this.impactScanDetails = this.impactScanService.getImpactScanFromState();

      if (!this.impactScanDetails) {
        this.impactScanService.navigateToPage('/dashboard/sme');
      } else {
        this.loading = false;
      }
    }

    this.navigationTarget.route += `/${this.dashboardService.getNavigationTarget().toLowerCase()}`;
  }

  initPage(): void {
    if (this.impactScanDetails) {
      this.impactScan = JSON.parse(JSON.stringify(this.impactScanDetails.questionnaire));
      while (this.impactScan.customers.length < 3) {
        this.impactScan.customers.push({ name: '', countryCode: '' });
      }
      if (this.impactScanDetails.viewOnly) {
        this.statusInfo = {
          name: `${this.impactScanDetails.firstName} ${this.impactScanDetails.lastName}`,
          status: `dashboard.status.${this.impactScanDetails.status.toLowerCase()}`,
          userDeleted: this.impactScanDetails.userStatus === UserStatus.INACTIVE,
        };
      } else {
        this.showCheckmarks = true;
      }
      this.initSidenav();
    }
  }

  getCategoriesForHeader(): CategoryListItemModel[] {
    if (
      this.impactScanDetails &&
      this.impactScanDetails.isPrefilled &&
      this.currentIndex === 0 &&
      !this.impactScanDetails.viewOnly &&
      this.impactScanDetails.status === 'DRAFT'
    ) {
      return [
        {
          categoryName: 'impact_scan.categories.company_context_one',
          isValid: (): boolean => true,
        },
        {
          categoryName: 'impact_scan.categories.company_context_two',
          isValid: (): boolean => false,
        },
        {
          categoryName: 'impact_scan.categories.governance',
          isValid: (): boolean => false,
        },
        {
          categoryName: 'impact_scan.categories.stakeholders',
          isValid: (): boolean => false,
        },
      ];
    }

    return this.getCategories();
  }

  private getCategories(): CategoryListItemModel[] {
    return [
      {
        categoryName: 'impact_scan.categories.company_context_one',
        isValid: (): boolean => this.validatorService.isValid('company_context_one', this.impactScan),
      },
      {
        categoryName: 'impact_scan.categories.company_context_two',
        isValid: (): boolean => this.validatorService.isValid('company_context_two', this.impactScan),
      },
      {
        categoryName: 'impact_scan.categories.governance',
        isValid: (): boolean => this.validatorService.isValid('governance', this.impactScan),
      },
      {
        categoryName: 'impact_scan.categories.stakeholders',
        isValid: (): boolean => this.validatorService.isValid('stakeholders', this.impactScan),
      },
    ];
  }

  revalidateSection(section: number): void {
    this.categoriesForHeader[section].isValid = (): boolean =>
      this.validatorService.isValid(this.sections[section], this.impactScan);
  }

  private initSidenav(): void {
    this.navMenu = [
      {
        matImg: 'business',
        title: 'impact_scan',
        route: '/dashboard/sme/impact-scan-v2/',
        exact: true,
      },
      {
        matImg: 'format_list_numbered',
        title: 'themes',
        route: this.impactScanDetails?.status !== 'DRAFT' ? '/dashboard/sme/impact-scan-v2/themes' : '',
      },
    ];
  }

  previous(): void {
    if (this.currentIndex > 0) {
      this.jumpToSection(this.currentIndex - 1);
    }
  }

  next(): void {
    if (this.currentIndex < 3) {
      this.jumpToSection(this.currentIndex + 1);
    }
  }

  async jumpToSection(section: number): Promise<void> {
    await this.getLatestImpactScanDetails();
    this.initPage();
    this.currentIndex = section;
    this.revalidateSection(section);
  }

  saveAnswer(answers: ImpactScanRevampModel): void {
    if (this.impactScanDetails && !this.impactScanDetails.questionnaireViewOnly) {
      this.impactScan = { ...answers };

      this.impactScanService.dispatchAction(
        new UpdateImpactScanAnswerAction({ id: this.impactScanDetails.id, answers: this.impactScan }),
      );
    }
  }

  lockAnswers(): void {
    if (!this.impactScanDetails) {
      return;
    }

    const unansweredQuestions: Set<UnansweredQuestionModel> = this.validatorService.validateImpactScan(this.impactScan);

    if (unansweredQuestions.size > 0) {
      this.highLightUnansweredQuestion = true;
      this.showUnansweredQuestionsPopup(unansweredQuestions);
    } else {
      this.showLockAnswersPopup();
    }
  }

  getWhiteButtonText(): string {
    return this.currentIndex > 0 ? 'buttons.previous' : '';
  }

  getYellowButtonText(): string | undefined {
    return this.currentIndex < 3 ? 'buttons.next' : this.showThemesButton();
  }

  private showThemesButton(): undefined | string {
    return this.impactScanDetails?.questionnaireViewOnly && this.impactScanDetails.status === 'DRAFT'
      ? undefined
      : 'impact_scan.proceed_button';
  }

  handleYellowButtonClick(): void {
    if (this.currentIndex < 3) {
      this.next();
    } else if (this.impactScanDetails?.status !== 'DRAFT') {
      this.impactScanService.navigateToPage('/dashboard/sme/impact-scan-v2/themes');
    } else {
      this.lockAnswers();
    }
  }

  private showUnansweredQuestionsPopup(unansweredQuestions: Set<UnansweredQuestionModel>): void {
    const popUpData = { questions: unansweredQuestions };

    this.dialog.open(UnansweredPopupSimpleComponent, { width: '60vw', autoFocus: false, data: popUpData });
  }

  private showLockAnswersPopup(): void {
    this.dialog
      .open(CompleteScoringCheckPopUpComponent, {
        width: '40vw',
        autoFocus: false,
        data: { translationGroup: 'calculate_themes' },
      })
      .afterClosed()
      .subscribe((proceed) => {
        if (proceed && this.impactScanDetails) {
          this.impactScanService.dispatchAction(new CalculateThemesAction({ id: this.impactScanDetails.id }));
        }
      });
  }
}
