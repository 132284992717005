import { Component, Input } from '@angular/core';
import { ImpactListModel } from '../../dashboard/assessment-list/impact-list.model';
import { DashboardService, GoToQuestionnaireAction, ProfileService } from '../../dashboard';
import { QuestionnaireType } from '../../dashboard/scoreboard/models/questionnaire.type';
import { UserStatus } from '../../dashboard/assessment-list/user-status.enum';
import { AssessmentListModel } from '../../dashboard/assessment-list/assessment-list.model';
import { ViewQuestionnaireAction } from '../../dashboard/xs/actions/view-questionnaire.action';
import { AssessmentVersion } from '../../dashboard/scoreboard/models/scoring.model';

@Component({
  selector: 'app-assessment-group',
  templateUrl: './assessment-group.component.html',
  styleUrls: ['./assessment-group.component.scss'],
})
export class AssessmentGroupComponent {
  @Input()
  impact!: ImpactListModel;

  @Input()
  expanded = false;

  protected readonly AssessmentVersion = AssessmentVersion;

  constructor(public readonly dashboardService: DashboardService, public profileService: ProfileService) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  startAssessment(): void {}

  goToImpact(impact: ImpactListModel): void {
    let improvementsId;

    if (impact.assessmentList && impact.assessmentList.length > 0) {
      for (const assessment of impact.assessmentList) {
        if (!assessment.unfinished) {
          improvementsId = assessment.id;
          break;
        }
      }
    }

    this.dashboardService.dispatchAction(
      new GoToQuestionnaireAction({
        id: impact.id,
        status: impact.status,
        type: QuestionnaireType.IMPACT_SCAN,
        version: AssessmentVersion.OLD,
        improvementsId,
        disabled: impact.disabled,
        unfinished: impact.unfinished,
        viewOnly: impact.viewOnly,
      }),
    );
  }

  goToAssessment(assessment: AssessmentListModel): void {
    this.dashboardService.dispatchAction(
      new GoToQuestionnaireAction({
        id: assessment.id,
        status: assessment.status,
        type: QuestionnaireType.ASSESSMENT,
        version: AssessmentVersion.OLD,
        disabled: assessment.disabled,
        unfinished: assessment.unfinished,
        viewOnly: assessment.viewOnly,
      }),
    );
  }

  isCurrentUser(userEmail: string): boolean {
    return userEmail === this.profileService.getProfile().email;
  }

  isInactive(userStatus: UserStatus): boolean {
    return userStatus === UserStatus.INACTIVE;
  }

  viewImpact(impact: ImpactListModel): void {
    this.dashboardService.dispatchAction(
      new ViewQuestionnaireAction({
        id: impact.id,
        status: impact.status,
        type: QuestionnaireType.IMPACT_SCAN,
        version: AssessmentVersion.OLD,
        userStatus: impact.userStatus,
        firstName: impact.firstName,
        lastName: impact.lastName,
        disabled: impact.disabled,
        viewOnly: impact.viewOnly,
        unfinished: impact.unfinished,
      }),
    );
  }

  viewAssessment(assessment: AssessmentListModel): void {
    this.dashboardService.dispatchAction(
      new ViewQuestionnaireAction({
        id: assessment.id,
        status: assessment.status,
        type: QuestionnaireType.ASSESSMENT,
        version: AssessmentVersion.OLD,
        userStatus: assessment.userStatus,
        firstName: assessment.firstName,
        lastName: assessment.lastName,
        disabled: assessment.disabled,
        viewOnly: assessment.viewOnly,
        unfinished: assessment.unfinished,
      }),
    );
  }

  isInProgress(scoring: AssessmentListModel): boolean {
    return scoring.unfinished && !scoring.disabled;
  }

  isCompleted(scoring: AssessmentListModel): boolean {
    return !scoring.unfinished && !scoring.disabled;
  }

  isDisabled(scoring: AssessmentListModel): boolean {
    return scoring.disabled;
  }

  canContinueImpact(scoring: AssessmentListModel): boolean {
    return scoring.unfinished && !scoring.viewOnly && scoring.version === 'ESG_MANAGED';
  }

  canContinue(scoring: AssessmentListModel): boolean {
    return scoring.unfinished && !scoring.viewOnly && scoring.assessmentVersion === AssessmentVersion.MANAGED;
  }

  hasResults(scoring: AssessmentListModel): boolean {
    return !scoring.unfinished;
  }

  canView(scoring: AssessmentListModel): boolean {
    return scoring.viewOnly;
  }

  isEligible(): boolean {
    return !(
      this.impact.assessmentList.find((assessment) => assessment.assessmentVersion !== AssessmentVersion.MANAGED) !==
      undefined
    );
  }
}
