import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialDesignModule, SharedModule } from '../shared';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { Co2CalculatorRouting } from './co2-calculator.routing';
import { Co2CalculatorComponent } from './co2-calculator-component/co2-calculator.component';
import { Co2CalculatorResultsComponent } from './co2-calculator-results/co2-calculator-results.component';
import { Co2CalculationListComponent } from './co2-calculation-list/co2-calculation-list.component';
// eslint-disable-next-line max-len
import { Co2CalculationListHeaderComponent } from './co2-calculation-list/co2-calculation-list-header/co2-calculation-list-header.component';
// eslint-disable-next-line max-len
import { Co2CalculationListItemComponent } from './co2-calculation-list/co2-calculation-list-item/co2-calculation-list-item.component';
// eslint-disable-next-line max-len
import { C02StartCalculationPopUpComponent } from './popups/c02-start-calculation-pop-up/c02-start-calculation-pop-up.component';
// eslint-disable-next-line max-len
import { Co2QuestionnaireGeneralComponent } from './co2-calculator-questionnaire/co2-questionnaire-general/co2-questionnaire-general.component';
// eslint-disable-next-line max-len
import { Co2QuestionnaireEnergyComponent } from './co2-calculator-questionnaire/co2-questionnaire-energy/co2-questionnaire-energy.component';
// eslint-disable-next-line max-len
import { Co2QuestionnaireMobilityComponent } from './co2-calculator-questionnaire/co2-questionnaire-mobility/co2-questionnaire-mobility.component';
// eslint-disable-next-line max-len
import { Co2QuestionnaireBoardComponent } from './co2-calculator-questionnaire/co2-questionnaire-board/co2-questionnaire-board.component';
// eslint-disable-next-line max-len
import { Co2QuestionnaireMaterialComponent } from './co2-calculator-questionnaire/co2-questionnaire-material/co2-questionnaire-material.component';
// eslint-disable-next-line max-len
import { Co2CalculatorQuestionnaireComponent } from './co2-calculator-questionnaire/co2-calculator-questionnaire.component';
// eslint-disable-next-line max-len
import { Co2NumericalQuestionComponent } from './co2-calculator-questionnaire/co2-numerical-question/co2-numerical-question.component';
// eslint-disable-next-line max-len
import { Co2YesNoQuestionComponent } from './co2-calculator-questionnaire/co2-yes-no-question/co2-yes-no-question.component';
import { ChartsModule } from 'ng2-charts';
import { Co2ChartComponent } from './co2-calculator-results/co2-chart/co2-chart.component';
// eslint-disable-next-line max-len
import { Co2KpiComponent } from './co2-calculator-results/co2-kpi/co2-kpi.component';
import { Co2CalculatorPopupComponent } from './popups/co2-calculator-popup/co2-calculator-popup.component';
// eslint-disable-next-line max-len
import { Co2CalculationStartPopupComponent } from './popups/co2-calculation-start-popup/co2-calculation-start-popup.component';
// eslint-disable-next-line max-len
import { Co2CalculationEndPopupComponent } from './popups/co2-calculation-end-popup/co2-calculation-end-popup.component';

@NgModule({
  declarations: [
    Co2CalculatorComponent,
    Co2CalculatorResultsComponent,
    Co2CalculationListComponent,
    Co2CalculationListHeaderComponent,
    Co2CalculationListItemComponent,
    C02StartCalculationPopUpComponent,
    Co2QuestionnaireGeneralComponent,
    Co2QuestionnaireEnergyComponent,
    Co2QuestionnaireMobilityComponent,
    Co2QuestionnaireBoardComponent,
    Co2QuestionnaireMaterialComponent,
    Co2CalculatorQuestionnaireComponent,
    Co2NumericalQuestionComponent,
    Co2YesNoQuestionComponent,
    Co2ChartComponent,
    Co2KpiComponent,
    Co2CalculatorPopupComponent,
    Co2CalculationStartPopupComponent,
    Co2CalculationEndPopupComponent,
  ],
  imports: [
    Co2CalculatorRouting,
    MaterialDesignModule,
    TranslateModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    ChartsModule,
  ],
})
export class Co2CalculatorModule {}
