import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../dashboard';
import { AuthService } from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/* eslint-disable @typescript-eslint/indent,prettier/prettier,no-trailing-spaces,no-shadow */
@Injectable({ providedIn: 'root' })
export class EeveryTranslateService {

  constructor(private translateService: TranslateService,
              private profileService: ProfileService,
              private authService: AuthService,
  ) {}

  init(): void {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang('en_GB');

    this.authService.initLocation()
      .finally(() => {
        this.refreshLanguage();
      });
  }

  refreshLanguage(): void {
    const ipLanguage = this.authService.getCountry();
    const profileLanguage = this.profileService.getLanguage();
    const browserLanguage = this.getLanguageFromBrowser();

    this.setLanguage(profileLanguage || ipLanguage || browserLanguage || 'GB');
  }

  private getLanguageFromBrowser(): string {
    for (const language of navigator.languages) {
      if (language.startsWith('en')) {
        return 'GB';
      }
      if (language.startsWith('nl')) {
        return 'NL';
      }
    }

    return 'GB';
  }

  private setLanguage(language: string): void {
    switch (language) {
      case 'GB':
        this.translateService.use('en_GB');
        break;
      case 'NL':
        this.translateService.use('nl_NL');
        break;
      default:
        this.translateService.use('en_GB');
        break;
    }
  }

  getLanguage(): string {
    return this.translateService.currentLang || 'nl_NL';
  }

  async get(key: string): Promise<string> {
    return this.translateService.get(key).toPromise();
  }

  onLanguageChange(): Observable<LangChangeEvent> {
    return this.translateService.onLangChange;
  }
}
