import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CompanyService, ProfileService } from '../profile';
import { ScoreboardService } from './scoreboard.service';
import {
  AssessmentVersion,
  ESGScore,
  GRIPercentage,
  ManagedEsgScore,
  ManagedGriScore,
  ScoringModel,
  ScoringWrapperModel,
} from './models/scoring.model';
import { ScoringStatus } from './models';
import { QuestionnaireType } from './models/questionnaire.type';
import { PopUpModel } from '../../shared/pop-up/pop-up.model';
import { PopUpComponent } from '../../shared/pop-up/pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { ResultsPopupComponent } from './results-popup/results-popup.component';
import { CheckResultsAction } from '../xs/actions/results-checked.action';
import { StartImpactScanAction } from '../questionnaire/impact-scan-revamp/xs/start-impact.action';
import { AssessmentSource, StartScoringAction } from '../xs';
import { DashboardService } from '../dashboard.service';
import { SetNavigationTargetAction } from '../xs/actions/set-navigation-target.action';
import { NavigationTargetEnum } from '../xs/navigation-target.enum';
import { SharePopupComponent } from './share-popup/share-popup.component';
import { ImpactListModel } from '../assessment-list/impact-list.model';
import { Topic } from './models/topic.model';
import { TranslateService } from '@ngx-translate/core';
import { ClearSelectedStateAssessmentAction } from './xs/clear-selected-state-assessment.action';
import { FilterService } from './filter/filter.service';

@Component({
  selector: 'app-scoreboard',
  templateUrl: './scoreboard.component.html',
  styleUrls: ['./scoreboard.component.scss'],
})
export class ScoreboardComponent implements OnInit, OnDestroy {
  companyName: string | undefined;

  fullName?: string;

  showValidate = true;

  impactOnly = false;

  subscription?: Subscription;

  scoring?: ScoringModel;

  scoringModel?: ScoringWrapperModel;

  results: ImpactListModel[] = [];

  scores: Topic[] = [];

  selectionLimit = 3;

  oldResults = false;

  oldAssessment = false;

  hasScoring = true;

  showButton = false;

  loading = true;

  hqCountry = '';

  constructor(
    private profileService: ProfileService,
    private scoreboardService: ScoreboardService,
    private companyService: CompanyService,
    private dashboardService: DashboardService,
    private translateService: TranslateService,
    private filterService: FilterService,
    private dialog: MatDialog,
  ) {}

  async ngOnInit(): Promise<void> {
    this.hqCountry = this.companyService.getCompany()?.country ?? 'NL';
    const profileModel = this.profileService.getProfile();

    if (this.scoreboardService.isFirstResultsCheck() && !profileModel.suppressSmePopUp) {
      this.dialog.open(ResultsPopupComponent, { width: '60vw', autoFocus: false });
      this.scoreboardService.dispatchAction(new CheckResultsAction({ hasBeenChecked: true }));
    }

    this.companyName = this.companyService.getCompany().name;
    this.scores = await this.getScores();

    this.updateScoreSelection(this.scores);
    await this.updateScoreDisplay();

    this.dashboardService.dispatchAction(
      new SetNavigationTargetAction({ navigationTarget: NavigationTargetEnum.SCOREBOARD }),
    );
    this.loading = false;
  }

  private configurePlaceholder(): void {
    if (this.dashboardService.hasImpact()) {
      if (this.dashboardService.impactNotDraft() && !this.dashboardService.hasAssessment()) {
        this.showButton = true;
      }
    } else {
      this.showButton = true;
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  async updateScoreDisplay(): Promise<void> {
    this.verifySelectionComingFromAssessmentList();

    this.scores.some((score) =>
      score.assessments.some((assessment) => {
        if (assessment.selected) {
          this.scoringModel = assessment.scoring;
          this.scoring = assessment.scoring?.deprecatedScoring;

          return true;
        }

        return false;
      }),
    );

    this.oldResults = this.scoringModel?.deprecatedLatest ?? false;

    this.configurePlaceholder();
    if (!this.scoring?.scores && !this.scoringModel?.scoring) {
      this.hasScoring = false;
      this.impactOnly = true;

      return;
    }

    if (!this.oldResults) {
      this.impactOnly = false;
      this.oldAssessment = false;
      this.fullName = `${this.scoringModel?.scoring.firstName} ${this.scoringModel?.scoring.lastName}`;
    } else {
      this.impactOnly = this.scoring?.type === QuestionnaireType.IMPACT_SCAN;
      this.showValidate = this.scoring?.status === ScoringStatus.READY_FOR_VALIDATION;
      this.oldAssessment = this.scoring?.assessmentVersion === AssessmentVersion.OLD;

      this.fullName = `${this.scoring?.firstName} ${this.scoring?.lastName}`;
    }
  }

  private verifySelectionComingFromAssessmentList(): void {
    const selectedAssessmentId = this.scoreboardService.getSelectedAssessment();

    if (selectedAssessmentId !== null) {
      this.scores.forEach((score) => {
        // eslint-disable-next-line no-param-reassign
        score.indeterminate = false;
        // eslint-disable-next-line no-param-reassign
        score.selected = false;
        score.assessments.forEach((assessment) => {
          const shouldSelect = assessment.id === selectedAssessmentId;

          // eslint-disable-next-line no-param-reassign
          assessment.selected = shouldSelect;
          if (shouldSelect) {
            // eslint-disable-next-line no-param-reassign
            score.indeterminate = true;
          }
        });
        if (score.assessments.every((assessment) => assessment.selected)) {
          // eslint-disable-next-line no-param-reassign
          score.selected = true;
        }
      });
      this.scoreboardService.dispatchAction(new ClearSelectedStateAssessmentAction());
    }
  }

  showSharePopup(): void {
    this.dialog.open(SharePopupComponent, {
      width: '60vw',
      autoFocus: false,
      data: {
        companyName: this.companyName,
        scores: this.scores,
        language: this.companyService.getCompany()?.country,
      },
    });
  }

  impactInfoPopup(): void {
    const data: PopUpModel = {
      title: 'scoreboard.impact.info_popup.title',
      message: 'scoreboard.impact.info_popup.description',
    };

    this.showPopUp(data);
  }

  sdgInfoPopup(): void {
    const data: PopUpModel = {
      title: 'scoreboard.sdg.info_popup.title',
      message: 'scoreboard.sdg.info_popup.description',
    };

    this.showPopUp(data);
  }

  griInfoPopup(): void {
    const data: PopUpModel = {
      title: 'scoreboard.gri.info_popup.title',
      message: 'scoreboard.gri.info_popup.description',
    };

    this.showPopUp(data);
  }

  csrdInfoPopup(): void {
    const data: PopUpModel = {
      title: 'scoreboard.csrd.info_popup.title',
      message: 'scoreboard.csrd.info_popup.description',
    };

    this.showPopUp(data);
  }

  showPopUp(data: PopUpModel): void {
    this.dialog.open(PopUpComponent, { width: '60vw', autoFocus: false, data });
  }

  newImpact(): void {
    this.scoreboardService.dispatchAction(new StartImpactScanAction());
  }

  newEsgAssessment(): void {
    this.scoreboardService.dispatchAction(new StartScoringAction({ source: AssessmentSource.DASHBOARD_WIDGET }));
  }

  getOutdatedEsgScore(): ESGScore | undefined {
    if (this.oldResults) {
      return this.scoringModel?.deprecatedScoring?.scores.ESG?.scores;
    }

    return undefined;
  }

  getEsgScore(): ManagedEsgScore[] | undefined {
    if (this.oldResults) {
      return undefined;
    }

    return this.scoringModel?.scoring.esg;
  }

  hasEsrsResults(): boolean {
    if (this.oldResults) {
      return false;
      // return (
      // eslint-disable-next-line max-len
      //   this.hasScoring && this.scoring?.scores.MATERIALITY !== undefined && this.scoring?.scores.MATERIALITY !== null
      // );
    }

    return this.scoringModel?.scoring?.esrs !== undefined && this.scoringModel?.scoring?.esrs !== null;
  }

  hasEsgResults(): boolean {
    if (this.oldResults) {
      return this.hasScoring && this.scoring?.scores.ESG !== undefined && this.scoring?.scores.ESG !== null;
    }

    return this.scoringModel?.scoring?.esg !== undefined && this.scoringModel?.scoring?.esg !== null;
  }

  hasSdgResults(): boolean {
    if (this.oldResults) {
      return this.hasScoring && this.scoring?.scores.SDG !== undefined && this.scoring?.scores.SDG !== null;
    }

    return (
      this.scoringModel?.scoring?.sdg !== undefined &&
      this.scoringModel?.scoring?.sdg !== null &&
      this.scoringModel?.scoring?.sdg?.length > 0
    );
  }

  hasEuTaxonomyResults(): boolean {
    if (this.oldResults) {
      return (
        this.hasScoring && this.scoring?.scores.EU_TAXONOMY !== undefined && this.scoring?.scores.EU_TAXONOMY !== null
      );
    }

    return this.scoringModel?.scoring?.euTax !== undefined && this.scoringModel?.scoring?.euTax !== null;
  }

  hasGriResults(): boolean {
    if (this.oldResults) {
      return this.hasScoring && this.scoring?.scores.GRI !== undefined && this.scoring?.scores.GRI !== null;
    }

    return this.scoringModel?.scoring?.gri !== undefined && this.scoringModel?.scoring?.gri !== null;
  }

  getOutdatedGriScore(): never | undefined {
    if (this.oldResults) {
      return this.scoringModel?.deprecatedScoring?.scores.GRI?.scores;
    }

    return undefined;
  }

  getOutdatedGriCounts(): GRIPercentage | undefined {
    if (this.oldResults) {
      return this.scoringModel?.deprecatedScoring?.scores.GRI?.counts;
    }

    return undefined;
  }

  getGriScore(): ManagedGriScore[] | undefined {
    if (this.oldResults) {
      return undefined;
    }

    return this.scoringModel?.scoring.gri;
  }

  private async getScores(): Promise<Topic[]> {
    return this.scoreboardService.getAllScores();
  }

  updateScoreSelection($event: Topic[]): void {
    this.scoreboardService.updateScoreSelection($event);
  }

  showScoreboardInfoPopup(): void {
    const data: PopUpModel = {
      title: 'scoreboard.esg.info.title',
      message: this.buildMessageBody(),
    };

    this.showPopUp(data);
  }

  buildMessageBody(): string {
    return this.translateService.instant('scoreboard.esg.info.text');
  }

  toggleDropdown(): void {
    this.filterService.toggleDropdownStatus();
  }
}
