import { Injectable } from '@angular/core';
import { AbstractService } from '../shared/services/abstract.service';
import { Co2OffsettingState } from './xs/co2-offsetting.state';
import { Observable } from 'rxjs';
import { Co2OffsettingResponseModel } from './model/co2-offsetting-response.model';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Co2OffsettingSummaryModel } from './model/co2-offsetting-summary.model';
// eslint-disable-next-line max-len
import { ContributionListItemModel } from './co2-offsetting-impact/co2-offsetting-impact-list/contribution-list-item.model';
import { CompanyService, ProfileService } from '../dashboard';
import { Co2OffsettingProjectModel } from './co2-offsetting-project/co2-offsetting-project.model';

@Injectable({ providedIn: 'root' })
export class Co2OffsettingService extends AbstractService {
  constructor(
    public store: Store,
    public router: Router,
    public http: HttpClient,
    private companyService: CompanyService,
    private profileService: ProfileService,
  ) {
    super(store, router, http);
  }

  isFirstChecked(): boolean {
    return this.store.selectSnapshot(Co2OffsettingState.isFirstOffsettingCheck);
  }

  getContributionList(): Observable<ContributionListItemModel[]> {
    return this.http.get<ContributionListItemModel[]>(`${this.apiBaseUrl}/co2offsetting/contributions`);
  }

  getProjects(): Observable<Co2OffsettingProjectModel[]> {
    let headers = new HttpHeaders();

    if (this.profileService.getLanguage()?.toLowerCase() === 'nl') {
      headers = headers.append('Accept-Language', 'nl-NL');
    } else {
      headers = headers.append('Accept-Language', 'en-NL');
    }

    return this.http.get<Co2OffsettingProjectModel[]>(`${this.apiBaseUrl}/co2offsetting/projects`, { headers });
  }

  createOffsettingGoal(offsettingGoal: number): Observable<Co2OffsettingResponseModel> {
    // eslint-disable-next-line prettier/prettier,max-len
    return this.http.post<Co2OffsettingResponseModel>(`${this.apiBaseUrl}/co2offsetting/goals`, { offsettingGoal });
  }

  getOffsettingGoal(): Observable<Co2OffsettingResponseModel> {
    return this.http.get<Co2OffsettingResponseModel>(`${this.apiBaseUrl}/co2offsetting/goals`);
  }

  setOffsettingGoal(offsettingGoal: number | undefined): Observable<Co2OffsettingResponseModel> {
    return this.http.put<Co2OffsettingResponseModel>(`${this.apiBaseUrl}/co2offsetting/goals`, { offsettingGoal });
  }

  getKpiSummary(): Observable<Co2OffsettingSummaryModel> {
    return this.http.get<Co2OffsettingSummaryModel>(`${this.apiBaseUrl}/co2offsetting/contributions/summary`);
  }

  getKpiSummaryByProject(projectId: string): Observable<Co2OffsettingSummaryModel> {
    return this.http.get<Co2OffsettingSummaryModel>(
      `${this.apiBaseUrl}/co2offsetting/contributions/summary/${projectId}`,
    );
  }

  contribute(project: string, subtotalString: string, totalString: string): Observable<unknown> {
    const amountBeforeVAT = +this.revertRadix(subtotalString);
    const amountAfterVAT = +this.revertRadix(totalString);

    return this.http.post(`${this.apiBaseUrl}/co2offsetting/contributions`, {
      amountBeforeVAT,
      amountAfterVAT,
      project,
    });
  }

  adminContribute(companyId: string, projectId: string, cost: string): Observable<unknown> {
    const amount = +this.revertRadix(cost);

    return this.http.post(`${this.apiBaseUrl}/co2offsetting/contributions/admin`, { companyId, projectId, amount });
  }

  getVATbyCountry(): number {
    return this.companyService.getCompany().country?.toLowerCase() === 'gb' ? 20 : 21;
  }

  formatRadix(s: string): string {
    const isNLStyle = this.companyService.getCompany().country?.toLowerCase() !== 'gb';

    if (!s.includes('.') && !s.includes(',')) {
      // eslint-disable-next-line no-param-reassign
      s += isNLStyle ? ',00' : '.00';
    }

    // For NL, replace dot with comma.
    return isNLStyle ? s.replace('.', ',') : s.replace(',', '.');
  }

  formatTonRadix(s: string): string {
    const isNLStyle = this.companyService.getCompany().country?.toLowerCase() !== 'gb';

    // For NL, replace dot with comma.
    return isNLStyle ? s.replace('.', ',') : s.replace(',', '.');
  }

  revertRadix(s: string): string {
    return s.replace(',', '.').replace('%', '');
  }

  formatDate(date: string): string {
    if (date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()];
      const day = d.getDate();

      return `${day < 10 ? '0' : ''}${day} ${month} ${year}`;
    }

    return 'N/A';
  }

  hasEllipsis(element: HTMLElement): boolean {
    return !element.textContent?.includes('...') ?? true;
  }

  getProjectPicture(mainImage: string): string {
    if (mainImage && mainImage.length > 0 && this.isImageFormat(mainImage)) {
      return mainImage;
    }

    return 'https://static.cawa.tech/assets/app_public_data/nf3.jpg';
  }

  isImageFormat(filename: string): boolean {
    return /\.(jpg|jpeg|png|gif|bmp|tiff|webp)$/i.test(filename);
  }

  getCurrency(): string {
    return this.companyService.getCompany().country?.toLowerCase() === 'gb' ? '£' : '€';
  }

  getIcon(projectType: string): string {
    switch (projectType.toUpperCase()) {
      case 'BIOCHAR':
        return 'local_fire_department';
      case 'AFFORESTATION':
        return 'park';
      case 'RENEWABLE ENERGY':
        return 'bolt';
      case 'ENHANCED WEATHERING':
        return 'landslide';
      case 'FOREST CONSERVATION':
        return 'forest';
      case 'HERBEBOSSING':
        return 'park';
      case 'HERNIEUWBARE ENERGIE':
        return 'bolt';
      case 'VERBETERDE VERWERING':
        return 'landslide';
      case 'BOSBEHOUD':
        return 'forest';
      default:
        return 'eco';
    }
  }
}
