import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionnaireRouting } from './questionnaire.routing';
import { QuestionnaireComponent } from './questionnaire.component';
import { MaterialDesignModule, SharedModule } from '../../../shared';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { QuestionsModule } from '../../../shared/questions/questions.module';
import { MaterialityComponent } from './materiality/materiality.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DoubleMaterialityComponent } from './materiality/double-materiality/double-materiality.component';
// eslint-disable-next-line max-len
import { EsgPrefillSelectorDropdownComponent } from './esg-prefill-popup/esg-prefill-selector-dropdown/esg-prefill-selector-dropdown.component';
import { EsgPrefillPopupComponent } from './esg-prefill-popup/esg-prefill-popup.component';

@NgModule({
  declarations: [
    QuestionnaireComponent,
    MaterialityComponent,
    DoubleMaterialityComponent,
    EsgPrefillSelectorDropdownComponent,
    EsgPrefillPopupComponent,
  ],
  imports: [
    QuestionnaireRouting,
    MaterialDesignModule,
    TranslateModule,
    CommonModule,
    SharedModule,
    QuestionsModule,
    ReactiveFormsModule,
    DragDropModule,
  ],
})
export class QuestionnaireModule {}
