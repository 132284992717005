import { ImpactScanModel, ImpactScanRevampDetailsModel } from './impact-scan-revamp.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { ImpactScanRevampService } from './impact-scan-revamp.service';
import { MatDialog } from '@angular/material/dialog';
import { StartImpactScanAction } from './xs/start-impact.action';
import { ImpactStartPopupComponent } from '../impact-scan/impact-start-popup/impact-start-popup.component';
import { UpdateImpactScanAnswerAction } from './xs/update-impact-scan-answer.action';
import { CalculateThemesAction } from './xs/calculate-themes.action';
// eslint-disable-next-line max-len
import { SubmitImpactScanAction } from './xs/submit-impact-scan.action';
import { DashboardService } from '../../dashboard.service';
import { ImpactFinishPopupComponent } from '../impact-scan/impact-finish-popup/impact-finish-popup.component';
import { AssessmentSource, StartScoringAction } from '../../xs';
import { IntroductionPopUpComponent } from '../../../shared/introduction-pop-up/introduction-pop-up.component';
import { WelcomePopupComponent } from '../welcome-popup/welcome-popup.component';
import { GoToImpactScanRevampAction } from '../../xs/actions/go-to-impact-scan-revamp.action';
import { ScoringStatus } from '../../scoreboard';
import { CheckAssessmentsAction } from '../../xs/actions/assessments-checked.action';
import { GetImpactScanAction } from './xs/get-impact-scan.action';
import { LogoutSuccessAction } from '../../../auth/xs/actions/logout-success.action';
import { LogoutFromSmeSuccessAction } from '../../../enterprise/xs/actions/logout-from-sme-success.actions';
import { RemoveThemeAction } from './xs/remove-theme.action';
import { AddThemeAction } from './xs/add-theme.action';
import {
  DialogPrefillResultModel,
  ImpactPrefillPopupComponent,
} from '../impact-scan/impact-prefill-popup/impact-prefill-popup.component';

export interface ImpactScanRevampStateModel {
  impactScanId?: number;
  impactScanRevampDetails?: ImpactScanRevampDetailsModel;
}

@State<ImpactScanRevampStateModel>({ name: 'impactScanRevamp' })
@Injectable()
export class ImpactScanRevampState {
  @Selector()
  static impactScanRevampDetails(state: ImpactScanRevampStateModel): ImpactScanRevampDetailsModel | undefined {
    return state.impactScanRevampDetails;
  }

  @Selector()
  static impactScanId(state: ImpactScanRevampStateModel): number | undefined {
    return state.impactScanId;
  }

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly impactScanRevampService: ImpactScanRevampService,
    private readonly ngZone: NgZone,
    private readonly dialog: MatDialog,
  ) {}

  @Action(StartImpactScanAction)
  startImpactScan(): void {
    const startImpactPopup = this.dialog.open(ImpactStartPopupComponent, { width: '60vw', autoFocus: false });

    startImpactPopup.afterClosed().subscribe((startImpact) => {
      if (startImpact) {
        this.impactScanRevampService.getAllCompletedImpactScans().then(async (scans) => {
          if (scans && scans.length) {
            this.startPrefilling(scans);
          } else {
            this.startNewImpactScan();
          }
        });
      }
    });
  }

  private startPrefilling(impactScans: ImpactScanModel[]): void {
    const prefillPopup = this.dialog.open(ImpactPrefillPopupComponent, {
      width: '60vw',
      autoFocus: false,
      data: { impactScans },
    });

    prefillPopup.afterClosed().subscribe((prefill: DialogPrefillResultModel): void => {
      if (prefill.continue) {
        if (prefill.prefillEnabled && prefill.selectedImpactScan) {
          this.startPrefilledImpactScan(prefill.selectedImpactScan);
        } else {
          this.startNewImpactScan();
        }
      } else if (prefill.back) {
        this.startImpactScan();
      }
    });
  }

  private startPrefilledImpactScan(selectedImpactScan: string): void {
    this.impactScanRevampService.startPrefilledImpactScan(selectedImpactScan).then((response) => {
      this.impactScanRevampService.dispatchAction(
        new GoToImpactScanRevampAction({
          id: response.id,
          status: ScoringStatus.DRAFT,
        }),
      );
    });
  }

  private startNewImpactScan(): void {
    this.impactScanRevampService.startNewImpactScan().then((response) => {
      this.impactScanRevampService.dispatchAction(
        new GoToImpactScanRevampAction({
          id: response.id,
          status: ScoringStatus.DRAFT,
        }),
      );
    });
  }

  @Action(GoToImpactScanRevampAction)
  async goToImpactScan(
    ctx: StateContext<ImpactScanRevampStateModel>,
    action: GoToImpactScanRevampAction,
  ): Promise<void> {
    ctx.patchState({ impactScanId: action.payload.id });
    await this.impactScanRevampService.dispatchActionAndWait(new GetImpactScanAction({ id: action.payload.id }));
    if (action.payload.status !== ScoringStatus.ANSWERS_LOCKED) {
      this.ngZone.run(() => this.impactScanRevampService.navigateToPage('/dashboard/sme/impact-scan-v2'));
    } else {
      this.ngZone.run(() => this.impactScanRevampService.navigateToPage('/dashboard/sme/impact-scan-v2/themes'));
    }
  }

  @Action(GetImpactScanAction)
  async getImpactScan(ctx: StateContext<ImpactScanRevampStateModel>, action: GetImpactScanAction): Promise<void> {
    const impactScan = await this.impactScanRevampService.getImpactScan(action.payload.id);

    ctx.patchState({ impactScanId: action.payload.id, impactScanRevampDetails: impactScan });
  }

  @Action(UpdateImpactScanAnswerAction)
  async saveAnswers(
    ctx: StateContext<ImpactScanRevampStateModel>,
    action: UpdateImpactScanAnswerAction,
  ): Promise<void> {
    const response = await this.impactScanRevampService.saveAnswer(action.payload.id, action.payload.answers);

    ctx.patchState({ impactScanRevampDetails: response });
  }

  @Action(CalculateThemesAction)
  async calculateThemes(ctx: StateContext<ImpactScanRevampStateModel>, action: CalculateThemesAction): Promise<void> {
    this.impactScanRevampService.startLoading();
    const response = await this.impactScanRevampService
      .lockAnswers(action.payload.id)
      .finally(() => this.impactScanRevampService.stopLoading());

    ctx.patchState({ impactScanRevampDetails: response });
    this.ngZone.run(() => this.impactScanRevampService.navigateToPage('/dashboard/sme/impact-scan-v2/themes'));

    this.dialog
      .open(WelcomePopupComponent, {
        width: '60vw',
        autoFocus: false,
        data: { translationGroup: 'impact_scan.themes.welcome_popup' },
      })
      .afterClosed()
      .subscribe((value) => {
        if (value) {
          this.ngZone.run(() => this.impactScanRevampService.navigateToPage('/dashboard/sme/impact-scan-v2'));
        }
      });
  }

  @Action(AddThemeAction)
  async addTheme(ctx: StateContext<ImpactScanRevampStateModel>, action: AddThemeAction): Promise<void> {
    this.impactScanRevampService.startLoading();
    const response = await this.impactScanRevampService
      .addTheme(action.payload.id, action.payload.themes)
      .finally(() => this.impactScanRevampService.stopLoading());

    ctx.patchState({ impactScanRevampDetails: response });
  }

  @Action(RemoveThemeAction)
  async removeTheme(ctx: StateContext<ImpactScanRevampStateModel>, action: RemoveThemeAction): Promise<void> {
    this.impactScanRevampService.startLoading();
    const response = await this.impactScanRevampService
      .removeTheme(action.payload.id, action.payload.theme)
      .finally(() => this.impactScanRevampService.stopLoading());

    ctx.patchState({ impactScanRevampDetails: response });
  }

  @Action(SubmitImpactScanAction)
  async submitImpactScan(ctx: StateContext<ImpactScanRevampStateModel>, action: SubmitImpactScanAction): Promise<void> {
    await this.impactScanRevampService.submit(action.payload.id);

    let showAssessmentPopup = false;

    if (this.dashboardService.isFirstAssessmentsCheck()) {
      this.dashboardService.dispatchAction(new CheckAssessmentsAction({ hasBeenChecked: true }));
      showAssessmentPopup = true;
    }

    this.ngZone.run(() => this.impactScanRevampService.navigateToPage('/dashboard/sme/assessments'));

    const impactScanFinishedPopup = this.dialog.open(ImpactFinishPopupComponent, {
      disableClose: true,
      autoFocus: false,
      width: '60vw',
    });

    impactScanFinishedPopup.afterClosed().subscribe((data: { startEsg: boolean }) => {
      if (data.startEsg) {
        this.dashboardService.dispatchAction(
          new StartScoringAction({ source: AssessmentSource.IMPACT_SCAN, impactScanId: action.payload.id }),
        );
        if (showAssessmentPopup) {
          this.dashboardService.dispatchAction(new CheckAssessmentsAction({ hasBeenChecked: false }));
        }
      } else if (showAssessmentPopup) {
        this.openAssessmentListPopUp();
      }
    });
  }

  @Action([LogoutSuccessAction, LogoutFromSmeSuccessAction])
  clearQuestionnaire(ctx: StateContext<ImpactScanRevampStateModel>): void {
    ctx.setState({});
  }

  private openAssessmentListPopUp(): void {
    this.dialog.open(IntroductionPopUpComponent, {
      width: '60vw',
      autoFocus: false,
      data: {
        title: 'dashboard.assessment_list.pop_up.title',
        message: 'dashboard.assessment_list.pop_up.description',
        list: [
          'dashboard.assessment_list.pop_up.item_one',
          'dashboard.assessment_list.pop_up.item_two',
          'dashboard.assessment_list.pop_up.item_three',
        ],
      },
    });
  }
}
