import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import ApexCharts from 'apexcharts';
import { GraphDataModel } from '../../../graph-data.model';

export interface Series {
  name: string;
  data: number[];
}

@Component({
  selector: 'app-esg-over-time',
  templateUrl: './esg-over-time.component.html',
  styleUrls: ['./esg-over-time.component.scss'],
})
export class EsgOverTimeComponent implements OnChanges, OnInit {
  @Input()
  scoringSets: GraphDataModel[][] = [];

  series: Series[] = [];

  chart!: ApexCharts;

  // eslint-disable-next-line @typescript-eslint/quotes
  years = "'20  '21  '22";

  async ngOnInit(): Promise<void> {
    await this.renderChart();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.scoringSets.currentValue) {
      this.series = [];
      this.formatYearLabels();

      if (this.chart) {
        await this.chart.updateOptions(this.getCategoryLabels());
        await this.chart.updateSeries(this.getSeries());
      }
    }
  }

  private getCategoryLabels(): Record<string, unknown> {
    return {
      xaxis: {
        categories: [
          [this.years, 'Economic'],
          [this.years, 'Environmental'],
          [this.years, 'Social'],
          [this.years, 'Governance'],
        ],
      },
    };
  }

  private async renderChart(): Promise<void> {
    this.formatYearLabels();
    this.chart = new ApexCharts(document.querySelector('#chart'), this.getChartOptions());
    await this.chart.render();
  }

  getChartOptions(): Record<string, unknown> {
    return {
      series: this.getSeries(),
      chart: {
        height: 210,
        width: '90%',
        type: 'bar',
        offsetX: 30,
        // offsetY: -20,
        toolbar: { show: false },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 400,
          animateGradually: {
            enabled: true,
            delay: 10,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 500,
          },
        },
      },
      fill: {
        colors: ['#4440E2'],
        opacity: 1,
        type: 'solid',
      },
      plotOptions: {
        bar: {
          borderRadius: 6,
          columnWidth: '50%',
          hideZeroBarsWhenGrouped: false,
          dataLabels: { position: 'top' },
        },
      },
      dataLabels: {
        enabled: true,
        formatter(val: string): string {
          return `${val}%`;
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
          fontWeight: 400,
          lineHeight: '17px',
          colors: ['#4440E2'],
        },
      },

      legend: { show: false },

      grid: { show: false },

      states: { hover: { filter: { type: 'none' } } },

      tooltip: { enabled: false },

      xaxis: {
        axisTicks: {
          show: true,
          offsetY: -66,
        },
        tickPlacement: 'between',
        categories: [
          [this.years, 'Economic'],
          [this.years, 'Environmental'],
          [this.years, 'Social'],
          [this.years, 'Governance'],
        ],
        labels: {
          style: {
            colors: [],
            fontSize: '14px',
            fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
            fontWeight: 700,
          },
          offsetY: 5,
        },
        position: 'bottom',
        axisBorder: { show: false },
      },
      yaxis: {
        min: -130,
        max: 130,
        axisBorder: { show: false },
        axisTicks: { show: false },
        labels: {
          show: false,
          formatter(val: string): string {
            return `${val}%`;
          },
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: '#D6D6D6', // Color of the line
            strokeDashArray: 0, // Solid line
            offsetX: 25,
            offsetY: 0,
            width: '96%',
            label: {
              text: 'Δ%',
              offsetX: 25,
              offsetY: 5,
              position: 'left',
              borderColor: '#F7F7F7',
              style: {
                fontSize: '13px',
                color: '#B6B6B6', // Text color
                // eslint-disable-next-line max-len
                background: '#F7F7F7',
                padding: {
                  left: 5,
                  right: 5,
                  top: -5,
                },
              },
            },
          },
        ],
      },
    };
  }

  getSeries(): Series[] {
    const scoringSets = [...this.scoringSets];

    scoringSets.forEach((scoringSet, index) => {
      const series: Series = {
        name: this.getYearFromScoringName(scoringSet),
        data: scoringSet.map((scoring, scoringIndex) => {
          if (index === 0) {
            return 0;
          }

          return scoring.percentage - scoringSets[index - 1][scoringIndex].percentage;
        }),
      };

      this.series.push(series);
    });

    return this.series;
  }

  private getYearFromScoringName(scoringSet: GraphDataModel[]): string {
    return scoringSet[0].alias?.split(' - ')[1].split(' ')[2] ?? '';
  }

  private formatYearLabels(): void {
    const scoringSets = [...this.scoringSets];

    if (this.scoringSets.length > 0) {
      this.years = scoringSets
        .map((scoringSet) => `'${this.getYearFromScoringName(scoringSet).substring(2)}`)
        .join(' ');
    }
  }

  getAbsolutSeries(): Series[] {
    const reversedScoringSets = [...this.scoringSets].reverse();
    const absolutSeries: Series[] = [];

    reversedScoringSets.forEach((scoringSet) => {
      const series: Series = {
        name: this.getYearFromScoringName(scoringSet),
        data: scoringSet.map((scoring) => scoring.percentage),
      };

      absolutSeries.push(series);
    });

    return absolutSeries;
  }
}
