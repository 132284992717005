import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UserStatus } from '../../../assessment-list/user-status.enum';
import { QuestionService } from '../../../../shared/questions';
import { ScoringStatus } from '../../../scoreboard';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../../dashboard.service';
import { StatusInfoModel } from '../../../../shared/questionnaire-header/status-info.model';
import { CategoryListItemModel } from '../../../../shared/questionnaire-header/category-list-item.model';
import { RelevantThemeModel } from '../../../../shared/relevant-theme/relevant-theme.model';
import { CdkDrag, CdkDragDrop, CdkDragMove, moveItemInArray } from '@angular/cdk/drag-drop';
import { MaterialityType } from './save-materiality-dto.model';
import { GetQuestionnaireAction } from '../../../../shared/questions/action/get-questionnaire.action';
import { SubmitScoringAction, SubmitScoringActionPayload } from '../../../xs';
// eslint-disable-next-line max-len
import { CompleteScoringCheckPopUpComponent } from '../../complete-scoring-check-pop-up/complete-scoring-check-pop-up.component';
import { merge, Subscription } from 'rxjs';
import { startWith, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-materiality',
  templateUrl: './materiality.component.html',
  styleUrls: ['./materiality.component.scss'],
})
export class MaterialityComponent implements OnInit, AfterViewInit, OnDestroy {
  statusInfo?: StatusInfoModel;

  showCheckmarks = false;

  disabledView = true;

  sectionIndex = 0;

  /* eslint-disable no-invalid-this */
  subCategories: CategoryListItemModel[] = [
    {
      categoryName: 'questionnaire.materiality.impact.title',
      isValid: (): boolean => this.impactCheckmark,
    },
    {
      categoryName: 'questionnaire.materiality.financial.title',
      isValid: (): boolean => this.financialCheckmark,
    },
    {
      categoryName: 'questionnaire.materiality.double.title',
      isValid: (): boolean => this.doubleMaterialityCheckmark,
    },
  ];
  /* eslint-enable no-invalid-this */

  navMenu = [
    {
      img: 'economic',
      title: 'economic',
      route: '../economic',
    },
    {
      img: 'environment',
      title: 'environmental',
      route: '../environmental',
    },
    {
      img: 'social',
      title: 'social',
      route: '../social',
    },
    {
      img: 'governance',
      title: 'governance',
      route: '../governance',
    },
    {
      matImg: 'format_list_numbered',
      title: 'materiality',
      route: '../materiality',
    },
  ];

  impactMaterialities: RelevantThemeModel[][] = [];

  impactCheckmark = false;

  financialMaterialities: RelevantThemeModel[][] = [];

  financialCheckmark = false;

  doubleMaterialities: RelevantThemeModel[][] = [];

  doubleMaterialityCheckmark = false;

  expansionPanelsDisabled = false;

  private animationFrame: number | undefined;

  constructor(
    private questionService: QuestionService,
    public dialog: MatDialog,
    public dashboardService: DashboardService,
  ) {}

  @ViewChild('scrollEl')
  scrollEl!: ElementRef<HTMLElement>;

  @ViewChildren(CdkDrag)
  dragEls!: QueryList<CdkDrag>;

  subs = new Subscription();

  ngOnInit(): void {
    if (!this.questionService.getScoringId()) {
      this.questionService.navigateToPage('/dashboard');
    }

    const scoring = this.questionService.getScoring();

    if (scoring.status === ScoringStatus.DRAFT) {
      this.questionService.navigateToPage('/dashboard/sme/questionnaire');
    }

    this.disabledView = scoring.viewOnly || false;
    this.showCheckmarks = !this.disabledView && (scoring.unfinished || false);

    this.updatePage();
  }

  updatePage(): void {
    const resultObservable = this.questionService.dispatchActionAndObserve(new GetQuestionnaireAction());

    resultObservable.subscribe((response) => {
      const questionnaire = this.questionService.getScoring();

      this.impactMaterialities = questionnaire.impactMateriality || [];
      this.impactCheckmark = !!questionnaire.impactMaterialityChanged;
      this.financialMaterialities = questionnaire.financialMateriality || [];
      this.financialCheckmark = !!questionnaire.financialMaterialityChanged;
      this.doubleMaterialities = questionnaire.prioritisedThemes || [];

      if (response) {
        if (this.disabledView) {
          let statusTranslation = 'dashboard.status.in_progress';

          if (questionnaire.disabled) {
            statusTranslation = 'dashboard.status.outdated';
          } else if (!questionnaire.unfinished) {
            statusTranslation = 'dashboard.status.completed';
          }
          this.statusInfo = {
            name: `${questionnaire.firstName} ${questionnaire.lastName}`,
            status: statusTranslation,
            userDeleted: questionnaire.userStatus === UserStatus.INACTIVE,
          };
        }
      }
    });
  }

  goToSection(index: number): void {
    this.sectionIndex = index;
    this.updatePage();
    this.scrollToTop();
  }

  scrollToTop(): void {
    const component = document.getElementById('page_container');

    if (component) {
      component.scrollTop = 0;
    }
  }

  previous(): void {
    if (this.sectionIndex > 0) {
      this.goToSection(this.sectionIndex - 1);
    } else {
      this.questionService.navigateToPage('/dashboard/sme/questionnaire/governance');
    }
  }

  next(): void {
    this.goToSection(this.sectionIndex + 1);
  }

  submit(): void {
    if (this.disabledView) {
      this.questionService.navigateToPage('/dashboard/sme/assessments');

      return;
    }

    this.doubleMaterialityCheckmark = true;
    const dialogRef = this.dialog.open(CompleteScoringCheckPopUpComponent, {
      width: '40vw',
      autoFocus: false,
      data: { translationGroup: 'complete_scoring' },
    });

    dialogRef.afterClosed().subscribe((finish: boolean) => {
      if (finish) {
        const scoring = this.questionService.getScoring() as SubmitScoringActionPayload;

        this.questionService.dispatchAction(new SubmitScoringAction(scoring));
      } else {
        this.doubleMaterialityCheckmark = false;
      }
    });
  }

  getYellowButtonText(): string | undefined {
    return this.sectionIndex < 2 ? 'questionnaire.button.next_step' : undefined;
  }

  getRedButtonText(): string | undefined {
    if (this.sectionIndex !== 2) {
      return undefined;
    }

    return this.disabledView ? 'questionnaire.button.back' : 'questionnaire.button.finish_scoring';
  }

  dropImpactMateriality(event: CdkDragDrop<RelevantThemeModel[][]>): void {
    moveItemInArray(this.impactMaterialities, event.previousIndex, event.currentIndex);
    this.questionService.saveMateriality(this.impactMaterialities, MaterialityType.IMPACT);
    this.impactCheckmark = true;
  }

  dropFinancialMateriality(event: CdkDragDrop<RelevantThemeModel[][]>): void {
    moveItemInArray(this.financialMaterialities, event.previousIndex, event.currentIndex);
    this.questionService.saveMateriality(this.financialMaterialities, MaterialityType.FINANCIAL);
    this.financialCheckmark = true;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngAfterViewInit(): void {
    const onMove$ = this.dragEls.changes.pipe(
      startWith(this.dragEls),
      map((d: QueryList<CdkDrag>) => d.toArray()),
      map((dragels) => dragels.map((drag) => drag.moved)),
      switchMap((obs) => merge(...obs)),
      tap(this.triggerScroll),
    );

    this.subs.add(onMove$.subscribe());

    const onDown$ = this.dragEls.changes.pipe(
      startWith(this.dragEls),
      map((d: QueryList<CdkDrag>) => d.toArray()),
      map((dragels) => dragels.map((drag) => drag.ended)),
      switchMap((obs) => merge(...obs)),
      tap(this.cancelScroll),
    );

    this.subs.add(onDown$.subscribe());
  }

  public triggerScroll($event: CdkDragMove): void {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = undefined;
    }
    this.animationFrame = requestAnimationFrame(() => this.scroll($event));
  }

  private cancelScroll(): void {
    if (this.animationFrame) {
      cancelAnimationFrame(this.animationFrame);
      this.animationFrame = undefined;
    }
  }

  private scroll($event: CdkDragMove): void {
    const speed = 10;
    const { y } = $event.pointerPosition;
    const baseEl = this.scrollEl.nativeElement;
    const box = baseEl.getBoundingClientRect();
    const { scrollTop } = baseEl;
    const top = box.top + -y;

    if (top > 0 && scrollTop !== 0) {
      baseEl.scrollTop = scrollTop - speed * Math.exp(top / 50);

      this.animationFrame = requestAnimationFrame(() => this.scroll($event));

      return;
    }

    const bottom = y - box.bottom;

    if (bottom > 0 && scrollTop < box.bottom) {
      baseEl.scrollTop = scrollTop + speed * Math.exp(bottom / 50);

      this.animationFrame = requestAnimationFrame(() => this.scroll($event));
    }
  }
}
