import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SubmitScoringAction, SubmitScoringActionPayload } from '../xs';
import { ScoreboardService, ScoringStatus } from '../scoreboard';
import { QuestionCategoryModel, QuestionModel, QuestionService } from '../../shared/questions';
import { MatDialog } from '@angular/material/dialog';
import { CheckUnansweredAction } from '../../accountant/xs/actions/check-unanswered.action';
import { SideNavModel } from '../../shared/sidenav';
import { UnansweredPopUpComponent } from '../../shared/unanswered-pop-up/unanswered-pop-up.component';
import { DashboardService } from '../dashboard.service';
import { UserStatus } from '../assessment-list/user-status.enum';
import { ProfileService } from '../profile';
import { QuestionnaireType } from '../scoreboard/models/questionnaire.type';
import { CategoryListItemModel } from '../../shared/questionnaire-header/category-list-item.model';
import { StatusInfoModel } from '../../shared/questionnaire-header/status-info.model';
import { AssessmentVersion } from '../scoreboard/models/scoring.model';
import { LockAnswersAction, LockAnswersActionPayload } from '../xs/actions/lock-answers.action';
// eslint-disable-next-line max-len
import { CompleteScoringCheckPopUpComponent } from './complete-scoring-check-pop-up/complete-scoring-check-pop-up.component';
import { GetQuestionnaireAction } from '../../shared/questions/action/get-questionnaire.action';

@Directive()
export abstract class AbstractQuestionnaireDirective implements OnInit, OnDestroy {
  abstract type: QuestionnaireType;

  category!: QuestionCategoryModel;

  subCategories!: CategoryListItemModel[];

  open = true;

  navMenu: SideNavModel[] = [];

  navMenuDisabled: SideNavModel[] = [];

  currentQuestions: QuestionModel[] = [];

  unansweredQuestions: QuestionModel[] = [];

  disabledView = false;

  sectionIndex = 0;

  subscription: Subscription | undefined;

  public reloaded = true;

  statusInfo?: StatusInfoModel;

  showCheckmarks = false;

  status: ScoringStatus = ScoringStatus.DRAFT;

  version: AssessmentVersion = AssessmentVersion.REVAMP;

  navigationTarget = { route: 'dashboard/sme', params: {} };

  constructor(
    protected questionService: QuestionService,
    private scoringService: ScoreboardService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    public dashboardService: DashboardService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.questionService.getScoringId()) {
      this.questionService.navigateToPage('/dashboard');
    }

    this.subscription = this.route.params.subscribe(async (params) => {
      await this.getLatestScoring();
      this.updateSidenav();
      // @ts-ignore
      this.category = await this.questionService.getSectionList(params.category);
      this.sectionIndex = 0;
      this.loadSubcategoriesForHeader();
      this.refreshCurrentQuestions(await this.getCurrentSection());
    });

    this.navigationTarget.route += `/${this.dashboardService.getNavigationTarget().toLowerCase()}`;
  }

  async getLatestScoring(): Promise<void> {
    await this.questionService.dispatchActionAndWait(new GetQuestionnaireAction());

    const scoring = this.questionService.getScoring();

    this.status = scoring.status || ScoringStatus.DRAFT;
    this.version = scoring.version || AssessmentVersion.REVAMP;

    if (scoring) {
      this.disabledView = scoring.viewOnly || false;
      this.showCheckmarks = !this.disabledView && (scoring.unfinished || false);
      if (this.disabledView) {
        let statusTranslation = 'dashboard.status.in_progress';

        if (scoring.disabled) {
          statusTranslation = 'dashboard.status.outdated';
        } else if (!scoring.unfinished) {
          statusTranslation = 'dashboard.status.completed';
        }
        this.statusInfo = {
          name: `${scoring.firstName} ${scoring.lastName}`,
          status: statusTranslation,
          userDeleted: scoring.userStatus === UserStatus.INACTIVE,
        };
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  async refreshSectionList(): Promise<void> {
    this.category = await this.questionService.getSectionList(this.category.category);
    this.refreshSubcategoriesForHeader();
  }

  async getCurrentSection(): Promise<QuestionModel[]> {
    this.questionService.startLoading();
    const questions: QuestionModel[] = await this.questionService.getSection(
      this.category.category,
      this.category.sections[this.sectionIndex].id,
      this.category.sections[this.sectionIndex].countryCode,
    );

    this.questionService.stopLoading();

    this.category.sections[this.sectionIndex].questions = questions;

    return questions;
  }

  setCurrentSectionValid(isValid: boolean): void {
    this.category.sections[this.sectionIndex].isValid = isValid;
    this.subCategories[this.sectionIndex].isValid = (): boolean => this.category.sections[this.sectionIndex].isValid;
  }

  redButtonClicked(): void {
    if (this.disabledView) {
      this.toDashboard();
    } else {
      this.checkUnansweredQuestionsAndLockAnswers();
    }
  }

  async checkUnansweredQuestionsAndLockAnswers(): Promise<void> {
    await this.questionService.dispatchActionAndWait(new CheckUnansweredAction());
    this.unansweredQuestions = (await this.questionService.getUnanswered()) || [];

    if (this.unansweredQuestions.length === 0) {
      if (this.version === AssessmentVersion.OLD) {
        this.submitScoring();
      } else {
        this.proceedToMateriality();
      }

      return;
    }

    const dialogRef = this.dialog.open(UnansweredPopUpComponent, {
      width: '60vw',
      autoFocus: false,
      data: { list: this.unansweredQuestions, canProceed: false },
    });

    dialogRef.afterClosed().subscribe(async () => {
      this.refreshCurrentQuestions(this.currentQuestions);
    });
  }

  trackByIdAndCountry(index: number, item: QuestionModel): string {
    return `id:${item.qid}, country:${item.countryCode}`;
  }

  addQuestions(questions: QuestionModel[]): void {
    const newList = this.currentQuestions.concat(questions);

    this.refreshCurrentQuestions(newList);
  }

  removeQuestions(questions: string[]): void {
    const newList = [...this.currentQuestions];

    for (const untriggeredQ of questions) {
      for (let i = 0; i < newList.length; i++) {
        if (untriggeredQ === newList[i].qid) {
          newList.splice(i, 1);
          i -= 1;
        }
      }
    }
    this.refreshCurrentQuestions(newList);
  }

  async previous(): Promise<void> {
    if (this.sectionIndex > 0) {
      await this.jumpToSection(this.sectionIndex - 1);
    } else {
      await this.getLatestScoring();
      this.updateSidenav();

      for (let i = 1; i < this.navMenu.length; i++) {
        const category = this.navMenu[i].title;

        if (this.category.category.toLowerCase() === category.toLowerCase()) {
          this.questionService.navigateToPage(
            `/dashboard/sme/questionnaire/${this.navMenu[i - 1].title.toLowerCase()}`,
          );

          this.scrollToTop();
        }
      }
    }
  }

  async yellowButtonClicked(): Promise<void> {
    if (this.sectionIndex < this.category.sections.length - 1) {
      // go to next section
      await this.jumpToSection(this.sectionIndex + 1);
    } else if (this.version === AssessmentVersion.OLD || this.category.category !== 'GOVERNANCE') {
      // check required answers and go to next ESG chapter
      this.handleNextEESGChapter();
    } else if (this.version === AssessmentVersion.REVAMP) {
      // go to Materiality chapter
      this.handleGoToMateriality();
    }
  }

  handleGoToMateriality(): void {
    if (this.disabledView) {
      if (this.status === ScoringStatus.DRAFT) {
        return;
      }
      this.questionService.navigateToPage('/dashboard/sme/questionnaire/materiality');
    } else if (this.status === ScoringStatus.ANSWERS_LOCKED) {
      this.questionService.navigateToPage('/dashboard/sme/questionnaire/materiality');
    } else {
      this.checkUnansweredQuestionsAndLockAnswers();
    }
  }

  async handleNextEESGChapter(): Promise<void> {
    await this.getLatestScoring();
    this.updateSidenav();

    if (this.disabledView) {
      this.nextChapter();

      return;
    }

    await this.questionService.dispatchActionAndWait(new CheckUnansweredAction({ category: this.category.category }));
    this.unansweredQuestions = (await this.questionService.getUnanswered()) || [];

    if (this.unansweredQuestions.length !== 0) {
      const dialogRef = this.dialog.open(UnansweredPopUpComponent, {
        width: '60vw',
        autoFocus: false,
        data: { list: this.unansweredQuestions, canProceed: true },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          this.nextChapter();
        } else {
          this.refreshCurrentQuestions(this.currentQuestions);
        }
      });
    } else {
      this.nextChapter();
    }
  }

  async nextChapter(): Promise<void> {
    await this.getLatestScoring();
    this.updateSidenav();

    for (let i = 0; i < this.navMenu.length - 1; i++) {
      const category = this.navMenu[i].title;

      if (this.category.category.toLowerCase() === category.toLowerCase()) {
        this.questionService.navigateToPage(`/dashboard/sme/questionnaire/${this.navMenu[i + 1].title.toLowerCase()}`);
      }
    }

    this.scrollToTop();
  }

  async jumpToSection(index: number): Promise<void> {
    await this.getLatestScoring();
    this.updateSidenav();

    await this.refreshSectionList();

    this.sectionIndex = index;

    this.refreshCurrentQuestions(await this.getCurrentSection());

    this.scrollToTop();
  }

  proceedToMateriality(): void {
    const dialogRef = this.dialog.open(CompleteScoringCheckPopUpComponent, {
      width: '40vw',
      autoFocus: false,
      data: { translationGroup: 'lock_answers' },
    });

    dialogRef.afterClosed().subscribe((finish: boolean) => {
      if (finish) {
        const scoring = this.questionService.getScoring() as LockAnswersActionPayload;

        this.questionService.dispatchAction(new LockAnswersAction(scoring));
      }
    });
  }

  submitScoring(): void {
    const dialogRef = this.dialog.open(CompleteScoringCheckPopUpComponent, {
      width: '40vw',
      autoFocus: false,
      data: { translationGroup: 'complete_scoring' },
    });

    dialogRef.afterClosed().subscribe((finish: boolean) => {
      if (finish) {
        const scoring = this.questionService.getScoring() as SubmitScoringActionPayload;

        this.questionService.dispatchAction(new SubmitScoringAction(scoring));
      }
    });
  }

  refreshCurrentQuestions(newValue: QuestionModel[]): void {
    newValue.forEach((question) => {
      // eslint-disable-next-line no-param-reassign
      question.highlighted = this.isUnansweredQuestion(question);
    });
    const sortedQuestions = newValue.sort(
      (firstQuestion, secondQuestion) => firstQuestion.ordering - secondQuestion.ordering,
    );

    this.category.sections[this.sectionIndex].questions = sortedQuestions;
    this.currentQuestions = [...sortedQuestions];
    this.reload();
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  isUnansweredQuestion(question: QuestionModel): boolean {
    return !this.unansweredQuestions.every((unansweredQuestion) => unansweredQuestion.qid !== question.qid);
  }

  reload(): void {
    this.reloaded = false;
    setTimeout(() => {
      this.reloaded = true;
    }, 0);
  }

  scrollToTop(): void {
    const component = document.getElementById('page_container');

    if (component) {
      component.scrollTop = 0;
    }
  }

  toDashboard(): void {
    this.questionService.navigateToPage('/dashboard/sme/assessments');
  }

  getWhiteButtonText(): string | undefined {
    if (this.sectionIndex > 0 || this.category.category !== 'ECONOMIC') {
      return 'questionnaire.button.prev_step';
    }

    return undefined;
  }

  getYellowButtonText(): string | undefined {
    if (this.sectionIndex < this.category.sections.length - 1 || this.category.category !== 'GOVERNANCE') {
      return 'questionnaire.button.next_step';
    }
    if (this.version === AssessmentVersion.REVAMP) {
      return this.showMaterialitiesButton();
    }

    return undefined;
  }

  private showMaterialitiesButton(): string | undefined {
    return this.disabledView && this.status === 'DRAFT' ? undefined : 'questionnaire.button.to_materiality';
  }

  getRedButtonText(): string | undefined {
    if (this.version === AssessmentVersion.REVAMP) {
      return undefined;
    }
    if (
      this.sectionIndex === this.category.sections.length - 1 &&
      (this.category.category === 'GENERAL' || this.category.category === 'GOVERNANCE')
    ) {
      return this.disabledView ? 'questionnaire.button.back' : 'questionnaire.button.finish_scoring';
    }

    return undefined;
  }

  loadSubcategoriesForHeader(): void {
    const assembledList: CategoryListItemModel[] = [];

    for (const subcategory of this.category.sections) {
      assembledList.push({
        categoryName: `enum.section.${subcategory.id.toLowerCase()}`,
        countryCode: subcategory.countryCode,
        isValid(): boolean {
          return subcategory.isValid;
        },
      });
    }
    this.subCategories = assembledList;
  }

  refreshSubcategoriesForHeader(): void {
    let index = 0;

    for (const subcategory of this.category.sections) {
      this.subCategories[index].categoryName = `enum.section.${subcategory.id.toLowerCase()}`;
      this.subCategories[index].countryCode = subcategory.countryCode;
      this.subCategories[index].isValid = (): boolean => subcategory.isValid;

      // eslint-disable-next-line no-plusplus
      index++;
    }
  }

  abstract updateSidenav(): void;
}
