import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

interface DOMRectI {
  bottom: number;
  height: number;
  left: number; // position start of element
  right: number; // position end of element
  top: number;
  width: number; // width of element
  x?: number;
  y?: number;
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[scrollToCenter]',
})
export class MatTabScrollToCenterDirective implements OnDestroy {
  isMobile: boolean | undefined;

  subs = new Subscription();

  constructor(private element: ElementRef) {
    this.subs.add(
      fromEvent(this.element.nativeElement, 'click').subscribe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (clickedContainer: any) => {
          if (clickedContainer.srcElement.className.indexOf('mat-tab-label') === -1) {
            return;
          }
          const scrollContainer = this.element.nativeElement.querySelector('.mat-tab-list');
          const currentScrolledContainerPosition: number = scrollContainer.scrollLeft;
          // const newPositionScrollTo = this.calcScrollValue(clickedContainer, currentScrolledContainerPosition);
          const newPositionScrollTo = this.calcScrollToCenterValue(clickedContainer, currentScrolledContainerPosition);

          scrollContainer.scroll({
            left: newPositionScrollTo,
            behavior: 'smooth',
          });
        },
      ),
    );
  }

  /** calculate scroll position to center of viewport */
  // eslint-disable-next-line max-len
  calcScrollToCenterValue(clickedContainer: { target: HTMLElement }, currentScrolledContainerPosition: number): number {
    const scrolledButton: DOMRectI = (clickedContainer.target as HTMLElement).getBoundingClientRect();
    const leftXOffset = (window.innerWidth - scrolledButton.width) / 2;
    const currentVisibleViewportLeft = scrolledButton.left;
    const neededLeftOffset = currentVisibleViewportLeft - leftXOffset;

    return currentScrolledContainerPosition + neededLeftOffset;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
