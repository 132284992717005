import { Component, OnInit } from '@angular/core';
import { AbstractCo2QuestionnaireDirective } from '../abstract-co2-questionnaire.directive';
import { CalculationInfoModel } from '../../../shared/info/calculation-info/calculation-info.model';

@Component({
  selector: 'app-co2-questionnaire-general',
  templateUrl: './co2-questionnaire-general.component.html',
  styleUrls: ['./co2-questionnaire-general.component.scss'],
})
export class Co2QuestionnaireGeneralComponent extends AbstractCo2QuestionnaireDirective implements OnInit {
  countries: string[] = ['NL', 'GB'];

  employeesDescription: CalculationInfoModel = { description: '' };

  heatingDescription: CalculationInfoModel = { description: '' };

  ngOnInit(): void {
    this.employeesDescription = { description: 'co2calculator.questionnaire.general.employees.description' };
    this.heatingDescription = { description: 'co2calculator.questionnaire.general.heating.description' };
  }
}
