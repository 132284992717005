import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Co2CalculatorComponent } from './co2-calculator-component/co2-calculator.component';
// eslint-disable-next-line max-len
import { Co2CalculatorQuestionnaireComponent } from './co2-calculator-questionnaire/co2-calculator-questionnaire.component';

const routes: Routes = [
  {
    path: '',
    component: Co2CalculatorComponent,
    pathMatch: 'full',
  },
  {
    path: 'questionnaire',
    component: Co2CalculatorQuestionnaireComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class Co2CalculatorRouting {}
