import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { LogoutSuccessAction } from '../../auth/xs/actions/logout-success.action';
import { EnterpriseStateModel } from '../../enterprise/xs/enterprise.state';
import { MatDialog } from '@angular/material/dialog';
import { CheckCo2OffsettingFirstTimeAction } from './action/check-co2-offsetting-first-time.action';
import { DefineCo2OffsettingGoalAction } from './action/define-co2-offsetting-goal.action';
// eslint-disable-next-line max-len
import { Co2OffsettingGoalPopupComponent } from '../popup/co2-offsetting-goal-popup/co2-offsetting-goal-popup.component';
import { Co2OffsettingGoalModel } from '../popup/co2-offsetting-goal-popup/co2-offsetting-goal.model';
import { Co2OffsettingService } from '../co2-offsetting.service';
import { Router } from '@angular/router';
import { Co2OffsettingResponseModel } from '../model/co2-offsetting-response.model';
import { UpdateOffsettingGoalStorageAction } from './action/update-offsetting-goal-storage.action';
import { tap } from 'rxjs/operators';
export interface Co2OffsettingStateModel {
  firstCo2OffsettingCheck?: boolean;
  offsettingGoalDefined?: boolean;
}

@State<Co2OffsettingStateModel>({
  name: 'co2offsetting',
  defaults: { firstCo2OffsettingCheck: true, offsettingGoalDefined: false },
})
@Injectable()
export class Co2OffsettingState {
  @Selector()
  static isFirstOffsettingCheck(state: Co2OffsettingStateModel): boolean {
    return state.firstCo2OffsettingCheck ?? true;
  }

  @Selector()
  static isOffsetGoalDefined(state: Co2OffsettingStateModel): boolean {
    return state.offsettingGoalDefined ?? false;
  }

  constructor(
    private readonly ngZone: NgZone,
    private readonly dialog: MatDialog,
    private co2offsettingService: Co2OffsettingService,
    private router: Router,
  ) {}

  @Action(UpdateOffsettingGoalStorageAction)
  updateOffsettingGoalStorage(ctx: StateContext<Co2OffsettingStateModel>): void {
    ctx.patchState({ offsettingGoalDefined: true });
  }

  @Action(CheckCo2OffsettingFirstTimeAction)
  co2PopupShown(ctx: StateContext<Co2OffsettingStateModel>, action: CheckCo2OffsettingFirstTimeAction): void {
    ctx.patchState({ firstCo2OffsettingCheck: !action.payload.hasBeenChecked });
  }

  @Action(DefineCo2OffsettingGoalAction)
  showOffsettingGoalPopup(ctx: StateContext<Co2OffsettingStateModel>): void {
    const dialog = this.dialog.open(Co2OffsettingGoalPopupComponent, {
      width: this.getDialogWidth(),
      height: this.getDialogHeight(),
      autoFocus: 'false',
      panelClass: 'custom-dialog-container',
    });

    dialog.afterClosed().subscribe((dialogResponse: Co2OffsettingGoalModel) => {
      if (dialogResponse.continue) {
        this.createOffsettingGoal(dialogResponse, ctx);
      }
      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur();
      }
    });
  }

  private getDialogWidth(): string {
    const viewportHeight = window.innerHeight;

    if (viewportHeight < 1080) {
      return '42vw';
    }
    if (viewportHeight < 768) {
      return '50vw';
    }
    if (viewportHeight < 600) {
      return '80vw';
    }

    return '42vw';
  }

  private getDialogHeight(): string {
    const viewportWidth = window.innerWidth;

    if (viewportWidth < 1366) {
      return '60vh';
    }
    if (viewportWidth < 1032) {
      return '90vh';
    }

    return '52vh';
  }

  @Action(LogoutSuccessAction)
  clearState(ctx: StateContext<EnterpriseStateModel>): void {
    ctx.setState({});
  }

  private createOffsettingGoal(
    dialogResponse: Co2OffsettingGoalModel,
    ctx: StateContext<Co2OffsettingStateModel>,
  ): void {
    this.co2offsettingService
      .createOffsettingGoal(dialogResponse.co2OffsettingGoal)
      .pipe(
        tap<Co2OffsettingResponseModel>((response: Co2OffsettingResponseModel) => {
          if (response.success) {
            ctx.patchState({ offsettingGoalDefined: dialogResponse.continue });
            this.router.navigate(['/refresh-state']).then(() => {
              this.router.navigate(['/dashboard/sme/co2offsetting']);
            });
          }
        }),
      )
      .subscribe();
  }
}
